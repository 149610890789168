<template>
  <div v-if="orgs.length > 0">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <org-details-form
              @updateOrgName="updateOrgName"
              :currentOrg="currentOrg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>
              Sorry, it seems like you do not have access to this page.
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrgDetailsForm from "@/components/Organisation/OrgDetailsForm";
import { useStore } from "vuex";
import { computed, reactive, ref, watch } from "vue";

export default {
  name: "VbOrganisation",
  components: {
    OrgDetailsForm,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters["user/user"]);
    const orgs = user.value.orgs.filter((org) => org);
    const currentOrg = reactive({});

    Object.assign(currentOrg, user.value.currentOrg);

    watch(user.value, () => {
      Object.assign(currentOrg, user.value.currentOrg);
    });

    return { user, orgs, currentOrg, store };
  },
  methods: {
    updateOrgName(name) {
      this.store.dispatch("user/UPDATE_ORG_NAME", {
        name: name,
      });
    },
  },
};
</script>
