<template>
  <a-form :model="formState" label-align="left" layout="vertical">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <h5>How To Install The Brivvio App</h5>
        <p>
          Click the App Store button below to install the Brivvio App (or scan
          the QR code using your iPhone).
        </p>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <a
          href="https://apps.apple.com/au/app/brivvio-caption-brand-videos/id1464479218"
        >
          <img src="../../asset/Appstore.png" id="appstore-button" />
        </a>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <a
          href="https://apps.apple.com/au/app/brivvio-caption-brand-videos/id1464479218"
        >
          <img src="../../asset/QR.png" id="qr-code" />
        </a>
      </div>
    </div>
    <div class="row">
      <h4>Account Details</h4>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <h5>Organisation</h5>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-9 col-9">
        <a-input
          v-model:value="formState.name"
          placeholder="Organisation Name"
          :readonly="currentOrg.role > 0"
        />
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-3">
        <button
          class="btn btn-primary px-3"
          @click="updateName"
          v-if="currentOrg.role < 1"
        >
          Update Name
        </button>
      </div>
      <div class="col-lg-12" v-if="currentOrg.role < 2">
        <h5>Subscription plan</h5>
        <h6>{{ formState.plan }}</h6>
      </div>
      <div class="col-lg-12" v-if="currentOrg.role < 2">
        <h5>Number Of Licenses</h5>
        <h6>{{ formState.licenses }}</h6>
      </div>
      <div class="col-lg-12" v-if="currentOrg.role < 2">
        <div v-if="isSubscribing">
          <h5 v-if="willExpire">Your subscription will expire on:</h5>
          <h5 v-else>Your subscription will automatically renew on:</h5>
          <h6>{{ formState.expiryDate }}</h6>
        </div>
        <div v-else>
          <button
            class="btn btn-primary px-3"
            style="margin-right: 20px;"
            @click="goToBilling"
          >
            Upgrade
          </button>
        </div>
      </div>
    </div>
  </a-form>
</template>
<script>
import { InboxOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref, reactive, watch } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    InboxOutlined,
  },
  emits: ["updateOrgName"],
  props: ["currentOrg"],
  setup(props) {
    const formState = reactive({
      name: "Organisation Name",
      licenses: "0",
      plan: "",
      expiryDate: 0,
    });
    const router = useRouter();

    const isSubscribing = ref(false);
    const willExpire = ref(false);
    const now = new Date().getTime() / 1000;

    const currentOrg = props.currentOrg;

    const OutsetaPlan = {
      Basic: "vW5x4X94",
      Starter: "79OwE49E",
      Team: "BWzAZl9E",
      Appstore: "XQY25R9P",
    };

    watch(props.currentOrg, () => {
      formState.name = currentOrg.name;
      formState.licenses = currentOrg.licenses;
      switch (currentOrg.currentPlan) {
        case OutsetaPlan.Basic:
          formState.plan = "Basic";
          isSubscribing.value = false;
          break;
        case OutsetaPlan.Starter:
          formState.plan = "Starter";
          isSubscribing.value = true;
          break;
        case OutsetaPlan.Appstore:
          formState.plan = "Appstore";
          isSubscribing.value = false;
          break;
        case OutsetaPlan.Team:
          formState.plan = "Team";
          isSubscribing.value = true;
          break;
      }
      formState.expiryDate = new Date(
        currentOrg.expiryDate * 1000
      ).toLocaleDateString();
      if (currentOrg.accountStage > 3) {
        willExpire.value = true;
      }
    });

    formState.name = currentOrg.name;
    formState.licenses = currentOrg.licenses;
    switch (currentOrg.currentPlan) {
      case OutsetaPlan.Basic:
        formState.plan = "Basic";
        isSubscribing.value = false;
        break;
      case OutsetaPlan.Starter:
        formState.plan = "Starter";
        isSubscribing.value = true;
        break;
      case OutsetaPlan.Appstore:
        formState.plan = "Appstore";
        isSubscribing.value = false;
        break;
      case OutsetaPlan.Team:
        formState.plan = "Team";
        isSubscribing.value = true;
        break;
    }
    formState.expiryDate = new Date(
      currentOrg.expiryDate * 1000
    ).toLocaleDateString();
    if (currentOrg.accountStage > 3) {
      willExpire.value = true;
    }

    return {
      formState,
      currentOrg,
      isSubscribing,
      willExpire,
      router,
    };
  },
  methods: {
    updateName() {
      this.$emit("updateOrgName", this.formState.name);
    },
    goToBilling() {
      this.router.push({ path: "/billing" });
    },
  },
});
</script>

<style scoped>
h5 {
  margin: 20px 0px;
}
h4 {
  margin-left: 15px;
  margin-top: 30px;
  margin-bottom: 10px;
}
#appstore-button {
  width: 200px;
}
#qr-code {
  padding-top: 20px;
  width: 200px;
}
</style>
